var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "content_box mt10" }, [
        _c("div", { staticClass: "flex_box" }, [
          _c("span", { staticClass: "tot_num" }, [
            _vm._v(_vm._s(_vm.$t("msg.ONEX010T010.011"))),
            _vm._v(" : "),
            _c("span", { staticClass: "num" }, [
              _vm._v(_vm._s(_vm.totalCount)),
            ]),
          ]),
          _c("span", { staticClass: "input_box" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.txtFilter,
                  expression: "txtFilter",
                },
              ],
              staticClass: "wid200",
              attrs: {
                type: "text",
                id: "filter",
                autocomplete: "off",
                placeholder: _vm.$t("msg.ONEX010T010.012"),
              },
              domProps: { value: _vm.txtFilter },
              on: {
                keyup: function ($event) {
                  return _vm.changeFilter()
                },
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.txtFilter = $event.target.value
                },
              },
            }),
          ]),
          _vm.relYn
            ? _c("span", { staticClass: "input_box" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selPo,
                        expression: "selPo",
                      },
                    ],
                    staticClass: "wid100",
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.selPo = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                    },
                  },
                  [
                    _c("option", { attrs: { value: "POL" } }, [
                      _vm._v("Departure"),
                    ]),
                    _c("option", { attrs: { value: "POD" } }, [
                      _vm._v("Arrival"),
                    ]),
                  ]
                ),
              ])
            : _vm._e(),
          _vm.relYn
            ? _c("span", { staticClass: "input_box" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selCtr,
                        expression: "selCtr",
                      },
                    ],
                    staticClass: "wid200",
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.selCtr = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                        function ($event) {
                          return _vm.checkSelectCtr()
                        },
                      ],
                    },
                  },
                  [
                    _c("option", { attrs: { value: "" } }, [
                      _vm._v(_vm._s(_vm.$t("msg.ONEX010T010.004"))),
                    ]),
                    _vm._l(_vm.ctrCdList, function (ctrInfo) {
                      return _c(
                        "option",
                        {
                          key: ctrInfo.ctrCd,
                          domProps: { value: ctrInfo.ctrCd },
                        },
                        [_vm._v(_vm._s(ctrInfo.ctrEnm))]
                      )
                    }),
                  ],
                  2
                ),
              ])
            : _vm._e(),
          _c("span", { staticClass: "ml_auto cal_btn col_43p tar" }, [
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.searchParams.kind !== "02" && _vm.pkupUsagYn === "Y",
                    expression:
                      "searchParams.kind !== '02' && pkupUsagYn === 'Y'",
                  },
                ],
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.onlyPickupYn,
                      expression: "onlyPickupYn",
                    },
                  ],
                  attrs: {
                    type: "checkbox",
                    id: "onlyPickupYn",
                    "true-value": "Y",
                    "false-value": "N",
                  },
                  domProps: {
                    checked: Array.isArray(_vm.onlyPickupYn)
                      ? _vm._i(_vm.onlyPickupYn, null) > -1
                      : _vm._q(_vm.onlyPickupYn, "Y"),
                  },
                  on: {
                    change: [
                      function ($event) {
                        var $$a = _vm.onlyPickupYn,
                          $$el = $event.target,
                          $$c = $$el.checked ? "Y" : "N"
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.onlyPickupYn = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.onlyPickupYn = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.onlyPickupYn = $$c
                        }
                      },
                      function ($event) {
                        return _vm.changeFilter()
                      },
                    ],
                  },
                }),
                _c(
                  "label",
                  { staticClass: "mr20", attrs: { for: "onlyPickupYn" } },
                  [_c("span"), _vm._v(_vm._s(_vm.$t("msg.ONEX010T010.136")))]
                ),
              ]
            ),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.searchParams.kind !== "02",
                    expression: "searchParams.kind !== '02'",
                  },
                ],
                staticClass: "ml20",
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.onlyCancelYn,
                      expression: "onlyCancelYn",
                    },
                  ],
                  attrs: {
                    type: "checkbox",
                    id: "onlyCancelYn",
                    "true-value": "Y",
                    "false-value": "N",
                  },
                  domProps: {
                    checked: Array.isArray(_vm.onlyCancelYn)
                      ? _vm._i(_vm.onlyCancelYn, null) > -1
                      : _vm._q(_vm.onlyCancelYn, "Y"),
                  },
                  on: {
                    change: [
                      function ($event) {
                        var $$a = _vm.onlyCancelYn,
                          $$el = $event.target,
                          $$c = $$el.checked ? "Y" : "N"
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.onlyCancelYn = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.onlyCancelYn = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.onlyCancelYn = $$c
                        }
                      },
                      function ($event) {
                        return _vm.changeFilter()
                      },
                    ],
                  },
                }),
                _c(
                  "label",
                  { staticClass: "mr20", attrs: { for: "onlyCancelYn" } },
                  [_c("span"), _vm._v(_vm._s(_vm.$t("msg.ONEX010T010.137")))]
                ),
              ]
            ),
            _c(
              "a",
              {
                staticClass: "button sm",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.exlDownload()
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("msg.MYIN020T010.016")))]
            ),
          ]),
        ]),
        _c("div", {
          style: `width: 100%; height: ${_vm.gridHeight}px`,
          attrs: { id: "realgrid" },
        }),
      ]),
      _c("div", { staticClass: "request_area" }, [
        _c(
          "div",
          {
            staticClass: "top",
            staticStyle: { cursor: "pointer" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.openRequestArea()
              },
            },
          },
          [
            _c("span", { staticClass: "tit" }, [
              _vm._v(_vm._s(_vm.$t("msg.ONEX010T010.013"))),
            ]),
            _c("span", { staticClass: "ntx" }, [
              _vm._v(_vm._s(_vm.$t("msg.ONEX010T010.014"))),
              _c("span", [
                _vm._v(
                  " (" +
                    _vm._s(
                      _vm.$t("msg.ONEX010T010.138", { cnt: _vm.checkedCount })
                    ) +
                    ")"
                ),
              ]),
            ]),
            _c(
              "button",
              {
                staticClass: "reqbtn up",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.openRequestArea()
                  },
                },
              },
              [_c("span", [_vm._v(_vm._s(_vm.$t("msg.ONEX010T010.015")))])]
            ),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "sub_area",
            staticStyle: { display: "block", cursor: "pointer" },
            attrs: { id: "working-progress-request-area" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.closeRequestArea()
              },
            },
          },
          [
            _c("div", { staticClass: "top" }, [
              _c("span", { staticClass: "tit" }, [
                _vm._v(_vm._s(_vm.$t("msg.ONEX010T010.013"))),
              ]),
              _c("span", { staticClass: "ntx" }, [
                _vm._v(_vm._s(_vm.$t("msg.ONEX010T010.014"))),
                _c("span", [
                  _vm._v(
                    " (" +
                      _vm._s(
                        _vm.$t("msg.ONEX010T010.138", { cnt: _vm.checkedCount })
                      ) +
                      ")"
                  ),
                ]),
              ]),
              _c(
                "button",
                {
                  staticClass: "reqbtn down",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.closeRequestArea()
                    },
                  },
                },
                [_c("span", [_vm._v(_vm._s(_vm.$t("msg.ONEX010T010.016")))])]
              ),
            ]),
            _c("div", { staticClass: "list" }, [
              _c("div", { staticClass: "item" }, [
                _c("p", [_vm._v(_vm._s(_vm.$t("msg.ONEX010T010.017")))]),
                _c("ul", [
                  _c(
                    "li",
                    {
                      class:
                        _vm.requestAreaBtn.booking01 === "Y" ? "" : "disabled",
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "link",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.fnRequestBooking("01")
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("msg.ONEX010T010.018")))]
                      ),
                    ]
                  ),
                  _vm.memberDetail.userCtrCd !== "CN"
                    ? _c(
                        "li",
                        {
                          class:
                            _vm.requestAreaBtn.booking02 === "Y"
                              ? ""
                              : "disabled",
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "link",
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.fnRequestBooking("02")
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("msg.ONEX010T010.019")))]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.memberDetail.userCtrCd !== "CN"
                    ? _c(
                        "li",
                        {
                          class:
                            _vm.requestAreaBtn.booking03 === "Y"
                              ? ""
                              : "disabled",
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "link",
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.fnRequestBooking("03")
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("msg.ONEX010T010.020")))]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.memberDetail.userCtrCd === "KR"
                    ? _c(
                        "li",
                        {
                          class:
                            _vm.requestAreaBtn.booking04 === "Y"
                              ? ""
                              : "disabled",
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "link",
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.fnRequestBooking("04")
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("msg.ONEX010T010.021")))]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.pkupUsagYn === "Y"
                    ? _c("li", [
                        _c(
                          "a",
                          {
                            staticClass: "link",
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.goPickupBookingPage()
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("msg.ONEX010T010.022")))]
                        ),
                      ])
                    : _vm._e(),
                ]),
              ]),
              _c("div", { staticClass: "item" }, [
                _c("p", [_vm._v(_vm._s(_vm.$t("msg.ONEX010T010.026")))]),
                _c("ul", [
                  _c(
                    "li",
                    {
                      class: _vm.requestAreaBtn.bl01 === "Y" ? "" : "disabled",
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "link",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.fnRequestBl("01")
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("msg.ONEX010T010.027")))]
                      ),
                    ]
                  ),
                  _vm.memberDetail.userCtrCd !== "CN"
                    ? _c(
                        "li",
                        {
                          class:
                            _vm.requestAreaBtn.bl02 === "Y" ? "" : "disabled",
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "link",
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.fnRequestBl("02")
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("msg.ONEX010T010.028")))]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.memberDetail.userCtrCd === "KR"
                    ? _c(
                        "li",
                        {
                          class:
                            _vm.requestAreaBtn.bl03 === "Y" ? "" : "disabled",
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "pop",
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.fnRequestBl("03")
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("msg.ONEX010T010.033")))]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "li",
                    {
                      class: _vm.requestAreaBtn.bl04 === "Y" ? "" : "disabled",
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "link",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.fnRequestBl("04")
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("msg.ONEX010T010.029")))]
                      ),
                    ]
                  ),
                  ["CN", "MY"].indexOf(_vm.memberDetail.userCtrCd) > -1
                    ? _c(
                        "li",
                        {
                          class:
                            _vm.requestAreaBtn.bl05 === "Y" ? "" : "disabled",
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "link",
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.fnCommonBtnAction("VGMPOP_OPEN")
                                },
                              },
                            },
                            [_vm._v("Input VGM")]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _c("li", { class: _vm.caReqYn === "Y" ? "" : "disabled" }, [
                    _c(
                      "a",
                      {
                        staticClass: "link",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.fnRequestBl("05")
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("msg.ONEX010T010.160")))]
                    ),
                  ]),
                  _vm.memberDetail.userCtrCd === "IN"
                    ? _c(
                        "li",
                        {
                          class:
                            _vm.requestAreaBtn.bl06 === "Y" ? "" : "disabled",
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "link",
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.fnRequestBl("06")
                                },
                              },
                            },
                            [_vm._v("B/L Issue Request")]
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
              ]),
              _c("div", { staticClass: "item" }, [
                _c("p", [_vm._v(_vm._s(_vm.$t("msg.ONEX010T010.023")))]),
                _c("ul", [
                  _c(
                    "li",
                    {
                      class:
                        _vm.requestAreaBtn.charge01 === "Y" ? "" : "disabled",
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "link",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.fnRequestCharge("01")
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("msg.ONEX010T010.071")))]
                      ),
                    ]
                  ),
                  _c(
                    "li",
                    {
                      class:
                        _vm.requestAreaBtn.charge02 === "Y" ? "" : "disabled",
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "pop",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.fnRequestCharge("02")
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("msg.ONEX010T010.024")))]
                      ),
                    ]
                  ),
                  _c(
                    "li",
                    {
                      class:
                        _vm.requestAreaBtn.charge03 === "Y" ? "" : "disabled",
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "pop",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.fnRequestCharge("03")
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("msg.ONGO080G010.034")))]
                      ),
                    ]
                  ),
                ]),
              ]),
              _c("div", { staticClass: "item" }, [
                _c("p", [_vm._v(_vm._s(_vm.$t("msg.ONEX010T010.030")))]),
                _c("ul", [
                  _vm.memberDetail.userCtrCd === "KR"
                    ? _c(
                        "li",
                        {
                          class:
                            _vm.requestAreaBtn.trans01 === "Y"
                              ? ""
                              : "disabled",
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "pop",
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.fnRequestTrans("01")
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("msg.ONEX010T010.031")))]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.memberDetail.userCtrCd === "KR"
                    ? _c(
                        "li",
                        {
                          class:
                            _vm.requestAreaBtn.trans02 === "Y"
                              ? ""
                              : "disabled",
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "pop",
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.fnRequestTrans("02")
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("msg.ONEX010T010.032")))]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.memberDetail.userCtrCd === "KR"
                    ? _c(
                        "li",
                        {
                          class:
                            _vm.requestAreaBtn.trans03 === "Y"
                              ? ""
                              : "disabled",
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "pop",
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.fnRequestTrans("03")
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("msg.ONEX010T010.072")))]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "li",
                    {
                      class:
                        _vm.requestAreaBtn.trans04 === "Y" ? "" : "disabled",
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "pop",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.fnRequestTrans("04")
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("msg.ONEX010T010.034")))]
                      ),
                    ]
                  ),
                  _c(
                    "li",
                    {
                      class:
                        _vm.requestAreaBtn.trans05 === "Y" ? "" : "disabled",
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "pop",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.fnRequestTrans("05")
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("msg.ONEX010T010.035")))]
                      ),
                    ]
                  ),
                  _vm.lang === "KOR"
                    ? _c(
                        "li",
                        {
                          class:
                            _vm.requestAreaBtn.trans06 === "Y"
                              ? ""
                              : "disabled",
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "pop",
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.fnRequestTrans("06")
                                },
                              },
                            },
                            [_vm._v("선적 위치")]
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
              ]),
              _c("div", { staticClass: "item" }, [
                _c("p", [_vm._v(_vm._s(_vm.$t("msg.ONEX010T010.037")))]),
                _c("ul", [
                  _c(
                    "li",
                    {
                      class:
                        _vm.requestAreaBtn.report01 === "Y"
                          ? "col_48p"
                          : "disabled col_48p",
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "pop",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.fnRequestReport("01")
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("msg.ONEX010T010.038")))]
                      ),
                    ]
                  ),
                  _vm.memberDetail.userCtrCd === "IN"
                    ? _c(
                        "li",
                        {
                          class:
                            _vm.requestAreaBtn.report05 === "Y" ||
                            _vm.nonFreightYn === "Y"
                              ? "col_48p"
                              : "disabled col_48p",
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "pop",
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.fnRequestReport("05")
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("msg.ONEX010T010.039")))]
                          ),
                        ]
                      )
                    : _c(
                        "li",
                        {
                          class:
                            _vm.requestAreaBtn.report02 === "Y"
                              ? "col_52p"
                              : "disabled col_52p",
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "pop",
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.fnRequestReport("02")
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("msg.ONEX010T010.040")))]
                          ),
                        ]
                      ),
                  _c(
                    "li",
                    {
                      class:
                        _vm.requestAreaBtn.report03 === "Y"
                          ? "col_48p"
                          : "disabled col_48p",
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "pop",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.fnRequestReport("03")
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("msg.ONEX010T010.042")))]
                      ),
                    ]
                  ),
                  _c(
                    "li",
                    {
                      class:
                        _vm.requestAreaBtn.report04 === "Y"
                          ? "col_52p"
                          : "disabled col_52p",
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "pop",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.fnRequestReport("04")
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("msg.ONEX010T010.044")))]
                      ),
                    ]
                  ),
                  _vm.memberDetail.userCtrCd !== "IN"
                    ? _c(
                        "li",
                        {
                          class:
                            _vm.requestAreaBtn.report05 === "Y" ||
                            _vm.nonFreightYn === "Y"
                              ? "col_48p"
                              : "disabled col_48p",
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "pop",
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.fnRequestReport("05")
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("msg.ONEX010T010.039")))]
                          ),
                        ]
                      )
                    : _c(
                        "li",
                        {
                          class:
                            _vm.requestAreaBtn.report02 === "Y"
                              ? "col_48p"
                              : "disabled col_48p",
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "pop",
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.fnRequestReport("02")
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("msg.ONEX010T010.040")))]
                          ),
                        ]
                      ),
                  _c(
                    "li",
                    {
                      class:
                        _vm.surrWaybillYn === "Y"
                          ? "col_48p"
                          : "disabled col_48p",
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "pop",
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.fnRequestReport("10")
                            },
                          },
                        },
                        [_vm._v("Surrender/Sea Waybill Notice")]
                      ),
                    ]
                  ),
                  _c(
                    "li",
                    {
                      class:
                        _vm.requestAreaBtn.report06 === "Y"
                          ? "col_48p"
                          : "disabled col_48p",
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "pop",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.fnRequestReport("06")
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("msg.ONEX010T010.041")))]
                      ),
                    ]
                  ),
                  _c(
                    "li",
                    {
                      class:
                        _vm.requestAreaBtn.report07 === "Y"
                          ? "col_48p"
                          : "disabled col_48p",
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "pop",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.fnRequestReport("07")
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("msg.ONEX010T010.043")))]
                      ),
                    ]
                  ),
                  _vm.memberDetail.userCtrCd === "ID"
                    ? _c(
                        "li",
                        {
                          class:
                            _vm.requestAreaBtn.report08 === "Y"
                              ? "col_52p"
                              : "disabled col_52p",
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "pop",
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.fnRequestReport("08")
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("msg.ONEX010T010.092")))]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "li",
                    {
                      class:
                        _vm.riderYn === "Y" ? "col_48p" : "disabled col_48p",
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "pop",
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.fnRequestReport("09")
                            },
                          },
                        },
                        [_vm._v("RIDER")]
                      ),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]
        ),
      ]),
      _c("common-unsolved", { ref: "commonUnsolved" }),
      _c(
        "win-layer-pop",
        { staticClass: "export_ongoing_list_popup" },
        [
          _vm.popupComponent
            ? _c(_vm.popupComponent, {
                tag: "component",
                attrs: { "parent-info": _vm.popupParams },
                on: { close: _vm.closePopup, callback: _vm.popupCallback },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "win-layer-pop",
        { staticClass: "export_ongoing_list_popup_tp2" },
        [
          _vm.popupTp2Component
            ? _c(_vm.popupTp2Component, {
                tag: "component",
                attrs: {
                  "ok-hidden": _vm.isHidden,
                  "is-show-pop": _vm.isShowPop,
                  detail: _vm.popupTp2Params,
                },
                on: { close: _vm.closeTp2Popup },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "win-layer-pop",
        { staticClass: "export_ongoing_list_popup_tp3" },
        [
          _vm.popupTp3Component
            ? _c(_vm.popupTp3Component, {
                tag: "component",
                attrs: { "parent-info": _vm.popupTp3Params },
                on: {
                  close: _vm.closeTp3Popup,
                  selectFunc: _vm.popupTp3Callback,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "win-layer-pop",
        { staticClass: "my_surcharge_pop" },
        [
          _vm.frtSurchargeComponent
            ? _c(_vm.frtSurchargeComponent, {
                tag: "component",
                attrs: { "parent-info": _vm.surChargeParams },
                on: { close: _vm.freSurchargeClosePopup },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm.commonBookingComponent
        ? _c(_vm.commonBookingComponent, {
            ref: "commonBooking",
            tag: "component",
            attrs: { "parent-info": _vm.commonBookingParams },
            on: { callback: _vm.commonBookingCallback },
          })
        : _vm._e(),
      _vm.commonBlComponent
        ? _c(_vm.commonBlComponent, {
            ref: "commonBL",
            tag: "component",
            attrs: { "parent-info": _vm.commonBlParams },
            on: { callback: _vm.commonBlCallback },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }